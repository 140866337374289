import React from 'react';

const PageNotFound = () => {
	return (
		<div className="flex flex-col items-center mt-32 mx-auto mb-20">
			<div className="flex justify-between">
				<div className="mr-[-120px]">
					<img
						src="images/Scarecrow.png"
						alt="404-Scarecrow"
						height="auto"
						width="700px"
					/>
				</div>
				<div className="flex flex-col items-center my-auto">
					<h1 className="items-center text-[49px] font-bold text-justify">
						404 Not found
					</h1>
					<h2 className="text-[24px] font-bold my-2">
						I have bad news for you
					</h2>
					<p className="text-[15px] my-2">
						The page you are looking for might be removed or is
						temporarily unavailable
					</p>
					<button
						className="mt-6 bg-[#002EFF] text-white text-center rounded-lg text-[20px] font-system font-bold w-fit hover:bg-[#002EFF] px-4 py-[6px]"
						onClick={() => (window.location.href = '/')}
					>
						Back to homepage
					</button>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
