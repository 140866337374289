import React from 'react';

const Footer = () => {
	return (
		<footer className="flex justify-center items-start h-fit bg-white shadow-[0_1px_100px_rgb(0,0,0,0.2)]">
			<div className="flex flex-row justify-center items-start py-12 mx-auto lg:w-[1007px]">
				<div className="flex-col w-8/12 pl-8">
					<div className="h-12">
						<img
							src="/images/header-logo.png"
							alt="Logo"
							className="h-12"
						/>
					</div>
					<div className="text-[14px] mr-8 lg:mr-[40%] md:mr-[10%] font-serif my-auto">
						Custom software development company that specializes in
						web, desktop and mobile application development.
					</div>
					<div className="flex h-1/4 items-end pt-8">
						<a
							href="https://www.linkedin.com/company/trinc"
							className="mr-4"
							target="_blank"
							rel="noreferrer"
						>
							<img
								alt=""
								width="auto"
								height="auto"
								src="/images/linkedin.svg"
							/>
						</a>
						<a
							href="https://twitter.com/trinc_in"
							className="mr-4"
							target="_blank"
							rel="noreferrer"
						>
							<img
								alt=""
								width="auto"
								height="auto"
								src="/images/twitter.svg"
							/>
						</a>
						<a
							href="https://www.youtube.com/@TrincAcademy?sub_confirmation=1"
							className="mr-4"
							target="_blank"
							rel="noreferrer"
						>
							<img
								alt=""
								width="auto"
								height="auto"
								src="/images/youtube.svg"
							/>
						</a>
						<a
							href="https://www.instagram.com/trinc_academy"
							className="mr-4"
							target="_blank"
							rel="noreferrer"
						>
							<img
								alt=""
								width="auto"
								height="auto"
								src="/images/instagram.svg"
							/>
						</a>
					</div>
				</div>
				<div className="flex flex-col w-4/12 pr-4 md:pr-12">
					<h1 className="font-serif h-1/3 text-[15px] font-extrabold w-full py-4">
						CONTACT
					</h1>
					<p className="font-serif text-[15px] h-[15%]">
						Contact Us:{' '}
						<a
							href="mailto:contact@trinc.in"
							className="text-blue-600 hover:underline"
						>
							contact@trinc.in
						</a>
					</p>
					<p className="font-serif text-[15px] h-[15%]">
						Apply for Job:{' '}
						<a
							href="mailto:hiring@trinc.in"
							className="text-blue-600 hover:underline"
						>
							hiring@trinc.in
						</a>
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
