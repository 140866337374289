import React from 'react';
import { defaultNavData } from './../constants';

const Header = ({ logo_path = '/images/header-logo.png', nav_data = defaultNavData, nav_padding = 'px-4' }) => {
	return (
		<header className="fixed top-0 left-0 w-full bg-white py-2 h-16 shadow-[0_4px_7px_rgba(0,0,0,0.25)]">
			<div className="flex flex-row justify-between mx-auto lg:w-[1007px]">
				{/* LOGO */}
				<div className="h-12" onClick={() => (window.location.href = '/')}>
					<img src={logo_path} alt="Company Logo" className="h-12" />
				</div>

				{/* NAVIGATIONS */}
				<div className="flex h-12 justify-center items-center pr-2">
					{nav_data.length > 0
						? nav_data.map((nav, index) => (
								<div key={index} className={`${nav_padding} ${nav.style}`}>
									<a href={nav.link}>{nav.title}</a>
								</div>
						  ))
						: null}
				</div>
			</div>
		</header>
	);
};

export default Header;
