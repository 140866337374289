import React from 'react';
import TalkButton, { AchievementCard } from './utils';

const Banner = () => {
	const bannerHeader = 'text-[49px] font-bold mb-8 leading-tight text-center lg:text-left';
	const BannerText =
		"At TRINC, we specialize in backend development, database design, and offer selective frontend services. Our focus is on building robust, scalable, and efficient systems that drive the backbone of your digital products. Whether you're in need of optimized databases or seamless API integrations, we're here to power your business with reliable and innovative solutions.";
	return (
		<section className="lg:mt-[150px] mt-[50px]">
			<div className="flex flex-col-reverse justify-between lg:flex-row px-4 mt-">
				<div className="flex flex-col lg:items-start items-center lg:pr-[50px] lg:w-[52%] w-[100%]">
					<h1 className={bannerHeader}>Your Gateway to Software Innovation!</h1>
					<p className="font-serif text-[16px] mb-8 leading-7 text-justify lg:text-left">{BannerText}</p>
					<TalkButton />
				</div>
				<div className="flex flex-row justify-center">
					<img src="/images/development.png" alt="" className="w-auto h-auto object-cover rounded-md" />
				</div>
			</div>
		</section>
	);
};

export default Banner;

export const AchievementSection = () => {
	return (
		<section className="flex flex-rows justify-center lg:justify-start py-8">
			<AchievementCard number="+50" description="PROJECTS COMPLETED" iconPath={'images/cup.svg'} />
			<AchievementCard number="+5" description="YEARS IN DEVELOPENT" iconPath={'images/diamond.svg'} />
		</section>
	);
};
