import React from 'react';
import { SingleBlogPage, BlogList } from '@base/common/components/blog';
import Header from '@base/common/components/header';
import Footer from '../components/footer';

export const BlogViewComponent = ({ single = false }) => {
	return (
		<>
			<Header />
			{single ? <SingleBlogPage /> : <BlogList />}
			<Footer />
		</>
	);
};
