import React from 'react';
import Banner, { AchievementSection } from './banner';
import Services from './services';
import Industries from './industries';
import ClientValues from './client-values';

const Wrapper = () => {
	return (
		<div className="wrapper lg:w-[1007px] mx-auto w-full mb-20">
			<Banner />
			<AchievementSection />
			<Services />
			<Industries />
			<ClientValues />
		</div>
	);
};

export default Wrapper;
