import React from 'react';
// import TalkButton, { AchievementCard } from './utils'

const SectionHeader = ({ head, text }) => {
	const bannerHeader = 'text-[42px] font-bold my-2 leading-tight';

	return (
		<div className="flex flex-col items-start md:pr-[50px]">
			<h1 className={bannerHeader}>{head}</h1>
			<div className="border-b-4 w-16 border-blue-500 mb-10" />
			<p className="font-serif text-6 text-justify my-[16px] leading-7">
				{text}
			</p>
		</div>
	);
};

export default SectionHeader;
