import React from 'react';
import SectionHeader from './sectionHeader';
import { DisplayCard } from './utils';

const Services = () => {
	const IndustryText =
		'Leverage our expertise across diverse sectors – Real Estate, Finance, Medical, and more – to develop contemporary and efficient digital solutions. At TRIC, we focus on understanding your requirements and crafting bespoke software solutions that contribute to the growth of your business.';
	const FinanceText =
		'We have successfully delivered innovative solutions. Our projects include financial software development, risk management systems, and seamless integration of financial technologies. We prioritize security, efficiency, and compliance, ensuring tailored solutions that meet the unique needs of our clients in the finance sector.';
	const MarketingText =
		'We have excelled in delivering impactful solutions. Our portfolio includes marketing automation tools, customer relationship management systems, and data-driven analytics platforms. We leverage our expertise to enhance your marketing strategies, ensuring targeted and measurable results for your business.';
	const AIText =
		'Our projects encompass machine learning models, natural language processing, and AI-driven automation. We apply cutting-edge technologies to empower your business with intelligent insights and automated processes, fostering innovation and efficiency in the AI/ML landscape.';
	const HealthText =
		'Our projects span electronic health record systems, medical data analytics, and telemedicine solutions. We prioritize data security, interoperability, and user-friendly interfaces to create tailored software that meets the complex demands of the healthcare industry.';
	const RealStateText =
		'Our portfolio includes property management systems, real estate CRM solutions, and innovative digital platforms. We specialize in developing solutions that streamline processes, enhance user experiences, and contribute to the growth of businesses within the Real Estate sector.';

	return (
		<section className="services mt-[75px]">
			<div className="px-3">
				<SectionHeader
					head="Insdustries we focus on"
					text={IndustryText}
				/>
				<DisplayCard
					head="Finance Domain"
					text={FinanceText}
					imgPath="images/stock-market.png"
					side="left"
				/>
				<DisplayCard
					head="Marketing Domain"
					text={MarketingText}
					imgPath="images/marketing.png"
					side="right"
				/>
				<DisplayCard
					head="AI/ML Domain"
					text={AIText}
					imgPath="images/ai.png"
					side="left"
				/>
				<DisplayCard
					head="Healthcare Domain"
					text={HealthText}
					imgPath="images/healthcare.png"
					side="right"
				/>
				<DisplayCard
					head="Real Estate"
					text={RealStateText}
					imgPath="images/real-estate.png"
					side="left"
				/>
			</div>
		</section>
	);
};

export default Services;
