import Header from '@base/common/components/header';
import Wrapper from '../components/wrapper';
import Footer from '../components/footer';

function HomePage() {
	return (
		<>
			<Header />
			<Wrapper />
			<Footer />
		</>
	);
}

export default HomePage;
