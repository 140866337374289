import Header from '@base/common/components/header';
import PaymentCard from '../components/paymentCard';

function RegistrationPage() {
	return (
		<div className="registration">
			<Header />
			<PaymentCard />
		</div>
	);
}

export default RegistrationPage;
