import Header from '@base/common/components/header';
import PageNotFound from '../components/not-found';
import Footer from '../components/footer';

function ErrorPage() {
	return (
		<div className="index">
			<Header />
			<PageNotFound />
			<Footer />
		</div>
	);
}

export default ErrorPage;
